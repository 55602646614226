<template>
    <div>
        <div class="row">
            <div class="col-md-12" v-if="$global.hasPermission('guestlistsview')">
                <KTCard>
                    <template v-slot:title>
                        {{operationTitle}}
                        <p><small>Event Guest List for Marketing.</small></p>
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <router-link to="/guests/create"
                                         v-if="globalEventId > 0 || $global.hasPermission('guestlistsstore')"
                            >
                                <b-button class="btn btn-primary font-weight-bolder"
                                          v-b-tooltip.hover :title="`Add New`"
                                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                                </b-button>
                            </router-link>
                            <b-button
                                :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
                                v-b-toggle.sidebar-backdrop-guests>
                                <i class="fa fa-filter fa-sm"></i> Filter

                            </b-button>
                        </b-input-group-append>
                    </template>
                    <template v-slot:body>
                        <v-card>
                            <div class="guests-table"
                                 v-if="$global.hasPermission('guestlistsview')">
                                <div class="w-100 mb-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_length"><label>Show
                                                <b-form-select :options="[10, 50, 100]"
                                                               class="datatable-select"
                                                               size="sm"
                                                               v-model="pagination.totalPerPage"></b-form-select>
                                                entries</label></div>

                                        </div>
                                        <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                                        <!--                                            <span v-for="(value,key) in filters"-->
                                        <!--                                                  :key="key">-->
                                        <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                                        <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                                        <!--                                                        <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                           @click="clearFilter(key, [])"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                                <span v-else>-->
                                        <!--                                                    <b-tag class="py-2"-->
                                        <!--                                                           v-if="value && key !== 'visible'"> <span-->
                                        <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                                        <!--                                                    <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                       @click="clearFilter(key , null)"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                            </span>-->
                                        <!--                                        </div>-->
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                                                <b-form-input class="form-control form-control-sm ml-2 w-25"
                                                              label="Search"
                                                              type="search"
                                                              v-model="search"
                                                              v-on:keyup.enter="handleSearch"></b-form-input>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <v-data-table
                                    :headers="columns"
                                    :items="dataSource"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    :loading="loading"
                                    class="table table-head-custom table-vertical-center table-responsive"
                                    hide-default-footer
                                    ref="table"
                                    responsive
                                >
                                    <template v-slot:item.id="record">
                                        {{ dataSource.length - record.index }}
                                    </template>
                                    <template v-slot:item.status="{item}">
                                        <b-tag v-if="item.status == 0" variant="default">Send Invitation
                                            Pending
                                        </b-tag>
                                        <b-tag v-if="item.status == 1" variant="primary">Inviation Send
                                        </b-tag>
                                        <b-tag v-if="item.status == 2" variant="success">Accepted</b-tag>
                                        <b-tag v-if="item.status == 3" variant="danger">Declined</b-tag>
                                    </template>
                                    <template v-slot:item.event="{item}">
                                        {{ item.event ? item.event.title : "" }}
                                    </template>
                                    <template v-slot:item.group="{item}">
                                        {{ item.group ? item.group.title : "" }}
                                    </template>
                                    <template v-slot:item.id="record">
                                        {{ dataSource.length - record.index }}
                                    </template>
                                    <template v-slot:item.event_from_date="{item}">
                                        {{ item.event ? $global.dateFormat(item.event.event_from_date) : ""}}
                                    </template>
                                    <template v-slot:item.event_to_date="{item}">
                                        {{ item.event ? $global.dateFormat(item.event.event_to_date) : ""}}
                                    </template>
                                    <template class="action-column" v-slot:item.action="{item}">
                                        <b-dropdown no-caret size="sm" toggle-class="text-decoration-none" class="mr-2"
                                                    variant="link">
                                            <template #button-content>
                                                <p class="btn btn-icon btn-light btn-hover-primary btn-sm">
                                                     <span
                                                         class="svg-icon svg-icon-md svg-icon-primary m-0">
                                                      <!--begin::Svg Icon-->
                                                      <inline-svg
                                                          class="action-edit-button"
                                                          src="/media/svg/icons/General/Settings-1.svg"
                                                      ></inline-svg>
                                                         <!--end::Svg Icon-->
                                                    </span>
                                                </p>
                                            </template>
                                            <b-dropdown-item @click="createLead(item)" href="#"
                                                             v-if="item.lead_create_button && $global.hasPermission('leadsview')">
                                                <span class="pb-1 pt-1">
                                                    <i class="fa fa-plus fa-sm mr-2"></i> Create Lead
                                                </span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="sendInvitation(item.id)" href="#"
                                                             v-if="(item.status !== 2 || item.status !== 3) && (new Date(item.invitation_send_date).setHours(0,0,0,0) !== new Date().setHours(0,0,0,0)) && !$global.hasPermission('owncompanyprofilesview')">
                                                <span class="pb-1 pt-1">
                                                    <i class="fa fa-plus fa-sm mr-2"></i> Send Invitation

                                                </span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="sendInvitationRequest(item.id)" href="#"
                                                             v-if="item.status === 0 && !$global.hasPermission('owncompanyprofilesview')">
                                                <span class="pb-1 pt-1">
                                                    <i class="fa fa-plus fa-sm mr-2"></i>  Send RSVP
                                                </span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <router-link :to="`guests/edit/${item.id}`">
                                            <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                               v-if="$global.hasPermission('guestlistsupdate')">
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                      class="action-edit-button"
                                                  ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </router-link>
                                        <a @click="handleDeleteOperation(item.id)"
                                           class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                                           size="sm"
                                           v-if="$global.hasPermission('guestlistsdestroy')">
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                  class="action-delete-button" src="/media/svg/icons/General/Trash.svg"
                                              ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            :per-page="pagination.totalPerPage"
                                            :total-rows="pagination.total"
                                            size="lg"
                                            v-model="pagination.current"
                                        ></b-pagination>

                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>

                <template>
                    <div>
                        <b-sidebar
                            :backdrop-variant="`transparent`"
                            backdrop
                            id="sidebar-backdrop-guests"
                            shadow
                            title="Filters"
                            right
                        >
                            <template #header="{ hide }">
                                <b-col sm="10">
                                    <strong id="sidebar-backdrop-leads___title__">Filters</strong>
                                </b-col>
                                <b-col sm="2">
                                    <button @click="hide();"
                                            class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                                        <i class="ki ki-close icon-xs text-muted"></i>
                                    </button>
                                </b-col>
                            </template>
                            <template #footer="{ hide }">
                                <div
                                    class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
                                    <b-button @click="hide();handleResetFilterClick()"
                                              class="btn btn-light-primary font-weight-bolder ml-2">
                                        <i class="fa fa-broom fa-sm"></i> Clear All
                                    </b-button>
                                </div>
                            </template>
                            <div class="px-10 py-4">
                                <b-col sm="12">
                                    <b-form-group
                                        label="Name Of Event"
                                        label-for="event_title">
                                        <b-form-input class="mb-2" id="filter-event_title"
                                                      v-model="filters.event_title"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        label="City"
                                        label-for="city">
                                        <b-form-input class="mb-2" id="filter-city"
                                                      v-model="filters.city"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </div>
                        </b-sidebar>
                    </div>
                </template>
                <!-- /.filter-container -->
            </div>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import KTCard from "@/view/content/Card.vue";
    import ListingMixin from "@/assets/js/components/ListingMixin";
    import ModuleMixin from "./module.mixin"

    const DEFAULT_FILTER_STATE = {
        event_title: null,
        city: null,
    };

    export default {
        mixins: [ListingMixin, ModuleMixin],
        data() {
            return {
                filters: {...DEFAULT_FILTER_STATE},
            }
        },
        components: {
            KTCard
        },
        methods: {
            handleResetFilterClick() {
                this.filters = {...DEFAULT_FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.checkFilterCountStatus()
            },
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
